/**
 * DAO 玩法弹窗
 */
import React from 'react'
import {
  Modal,
  Text,
  Flex,
  HelpIcon,
  BalanceInput,
  Ticket,
  useTooltip,
  Skeleton,
  Button,
  ArrowForwardIcon,
  FlexProps,
  Box,
} from '@pancakeswap/uikit'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { useTranslation } from 'contexts/Localization'
import { useWeb3React } from '@web3-react/core'

const StyledModal = styled(Modal)`
  min-width: 320px;
  max-width: 370px;
  background: #fff7f7;
  & > div {
    border: 0;
  }
`
const BalancePanel = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 10;
`
const BameBackground = styled(BalancePanel)`
  background: url('/images/luck/Play_gift.png');
  background-repeat: no-repeat;
  background-size: cover;
`

interface ModalProps {
  onDismiss?: () => void
}

const GameRuleModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { account } = useWeb3React()
  const { t } = useTranslation()
  const { theme } = useTheme()
  console.info('account', account, theme)

  return (
    <StyledModal title={t('Luck Event Details')} onDismiss={onDismiss}>
      <BameBackground />
      <Flex
        flexDirection="column"
        mt="15px"
        style={{ padding: '16px', borderRadius: '10px', backgroundColor: '#C4EAB6' }}
      >
        {/* <Text style={{ color: '267638', fontWeight: 'bold', paddingBottom: '20px' }} fontSize={22} >
                    {t('Luck Event Details')}
                </Text> */}
        <Text mb="15px" color={theme.isDark ? 'white' : '#218634'} fontSize={14}>
          1、{t('Luck Event Details One')}
        </Text>
        <Text mb="15px" color={theme.isDark ? 'white' : '#218634'} fontSize={14}>
          2、{t('Luck Event Details Two')}
        </Text>
        <Text mb="15px" color={theme.isDark ? 'white' : '#218634'} fontSize={14}>
          3、{t('Luck Event Details Three')}
        </Text>
        <Text mb="15px" color={theme.isDark ? 'white' : '#218634'} fontSize={14}>
          4、{t('Luck Event Details Four')}
        </Text>
      </Flex>
    </StyledModal>
  )
}

export default GameRuleModal
