import { useTranslation } from 'contexts/Localization'
import { BaseLayout, CardsLayout } from '@pancakeswap/uikit'
import React from 'react'
import styled from 'styled-components'
import LuckPacket from './LuckPacket'

const PacketGrid = styled.div`
  display: flex;
  column-gap: 0px;
  row-gap: 0px;
  flex-flow: row wrap;
  justify-content: center;
`

export default function LuckGrid({
  coinType,
  tokenIndex,
  redPackets = [],
  onShowDetail = (redPacket: any) => undefined,
}) {
  const { t } = useTranslation()
  return (
    <>
      <PacketGrid>
        {redPackets.map((value) => (
          <LuckPacket
            key={`${coinType}-${value.configId}-${value.id}`}
            redPacketProp={value}
            coinType={coinType}
            tokenIndex={tokenIndex}
            onShowDetail={onShowDetail}
          />
        ))}
      </PacketGrid>
    </>
  )
}
