import React, { useEffect, useState } from 'react'
import { useTranslation } from 'contexts/Localization'
import { useMediaQuery, useScreen } from 'usehooks-ts'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import Bignumber from 'bignumber.js'
import { useRedPacketContract } from 'hooks/useContract'
import * as dayjs from 'dayjs'
import { ChevronLeftIcon, Link, ButtonMenu, ButtonMenuItem } from '@pancakeswap/uikit'

const Row = styled.div`
  margin-bottom: 32px;

  & > button + button {
    margin-left: 16px;
  }
`

const LuckBody = styled.div`
  width: 100%;
  height: 775.5px;
  background: linear-gradient(#88C877, #D3EDBC );
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 20;
`

const BackNav = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const List = styled.div`
  width: 93%;
  border-radius: 15px;
  height: calc(100% - 150px);
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
`

const ListContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  .list-item {
    width: 95%;
    border-bottom: 1px solid #e4e5e7;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .list-item-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
`

const ListLink = styled.a`
  font-size: 14px;
  cursor: pointer;
`

export default function LuckHistory({
  onBack = () => undefined,
  tokenMap = {},
  onShowDetail = (redPacket: any) => undefined,
}) {
  const { t } = useTranslation()
  const redPacketContract = useRedPacketContract(true)
  const { account } = useWeb3React()

  const [currentIndex, setCurrentIndex] = useState(0)
  const [mySendPackets, setMySendPackets] = useState([])
  const [myJoinedPackets, setMyJoinedPackets] = useState([])

  useEffect(() => {
    const fetchHistory = async (configId) => {
      const sendCount = await redPacketContract.getUserSendRedPacketHistoryCount(account, configId)
      const startIndex = sendCount < 10 ? 0 : sendCount - 10
      redPacketContract.getUserSendRedPacketHistoryBatch(account, configId, startIndex, sendCount).then((res) => {
        res.forEach((redPacketId) => {
          redPacketContract.getRedPacketDetail(configId, redPacketId.toNumber()).then((pack) => {
            setMySendPackets((oldPackets) => [
              ...oldPackets,
              {
                ...pack,
                ptype: 'send',
                configId,
                redPacketId,
              },
            ])
          })
        })
      })
    }

    const fetchData = async () => {
      const count = await redPacketContract.totalConfigs()
      for (let i = 0; i < count; i++) {
        fetchHistory(i)
      }
    }

    fetchData()
  }, [redPacketContract])

  //
  useEffect(() => {
    const fetchHistory = async (configId) => {
      const sendCount = await redPacketContract.getUserJoinedRedPacketHistoryCount(account, configId)
      const startIndex = sendCount < 10 ? 0 : sendCount - 10
      redPacketContract.getUserJoinedRedPacketHistoryBatch(account, configId, startIndex, sendCount).then((res) => {
        res.forEach((redPacketId) => {
          redPacketContract.getRedPacketDetail(configId, redPacketId.toNumber()).then((pack) => {
            if (pack.userAmounts.length > 0) {
              setMyJoinedPackets((oldPackets) => [
                ...oldPackets,
                {
                  ...pack,
                  ptype: 'take',
                  configId,
                  redPacketId,
                },
              ])
            }
          })
        })
      })
    }

    const fetchData = async () => {
      const count = await redPacketContract.totalConfigs()
      for (let i = 0; i < count; i++) {
        fetchHistory(i)
      }
    }

    fetchData()
  }, [redPacketContract])

  const matches = useMediaQuery('(max-width:436px)')
  const screen = useScreen()
  const balanceStyle = {
    width: '388.89px',
  }
  const bodyHeight = {}
  if (matches) {
    Object.assign(balanceStyle, {
      transform: 'scale(0.85)',
    })

    Object.assign(bodyHeight, {
      height: `${(screen.width / 436) * 775.5}px`,
    })
  }

  const handleClick = (newIndex) => {
    setCurrentIndex(newIndex)
  }

  return (
    <LuckBody style={bodyHeight}>
      <BackNav>
        <ChevronLeftIcon width={32} height={32} style={{ position: 'absolute', left: '12px' }} onClick={onBack} />
        <span style={{ fontWeight: 'bold' }}>{t('Luck Detail')}</span>
      </BackNav>
      <Row>
        <ButtonMenu activeIndex={currentIndex} onItemClick={handleClick}>
          <ButtonMenuItem>{t('Red Envelopes I Sent')}</ButtonMenuItem>
          <ButtonMenuItem>{t('Red Envelopes I Grabbed')}</ButtonMenuItem>
        </ButtonMenu>
      </Row>
      <List>
        {currentIndex === 0 && (
          <ListContent>
            {mySendPackets.map((i) => (
              <div key={`${i.ptype}_${i.configId}_${i.redPacketId}`} className="list-item">
                <div className="list-item-row">
                  <span>
                    {i.participantsNum.toNumber()}
                    {t('Luck Total Number')}
                  </span>
                  <span style={{ color: '267638' }}>
                    - {new Bignumber(i.totalAmount.toBigInt()).div(1e18).toNumber()}&nbsp;
                    {tokenMap[i.tokenContract]}
                  </span>
                </div>
                <div className="list-item-row">
                  <span style={{ color: '#ACACAC', fontSize: '14px' }}>
                    {dayjs.unix(i.timestamp.toNumber()).format('MMMM D, YYYY h:mm A')}
                  </span>
                  <ListLink
                    onClick={() => {
                      onShowDetail(i)
                    }}
                  >
                    {t('Red Envelope Details')}&nbsp;&gt;&gt;
                  </ListLink>
                </div>
              </div>
            ))}
          </ListContent>
        )}
        {currentIndex === 1 && (
          <ListContent>
            {myJoinedPackets.map((i) => (
              <div key={`${i.ptype}_${i.configId}_${i.redPacketId}`} className="list-item">
                <div className="list-item-row">
                  <span>
                    {i.participantsNum.toNumber()}
                    {t('Luck Total Number')}
                  </span>
                  <span style={{ color: 'green' }}>
                    + {new Bignumber(i.userAmounts[i.joinedUsers.indexOf(account)].toBigInt()).div(1e18).toNumber()}
                    &nbsp;
                    {tokenMap[i.tokenContract]}
                  </span>
                </div>
                <div className="list-item-row">
                  <span style={{ color: '#ACACAC', fontSize: '14px' }}>
                    {dayjs.unix(i.timestamp.toNumber()).format('MMMM D, YYYY h:mm A')}
                  </span>
                  <ListLink
                    onClick={() => {
                      onShowDetail(i)
                    }}
                  >
                    {t('Red Envelope Details')}&nbsp;&gt;&gt;
                  </ListLink>
                </div>
              </div>
            ))}
          </ListContent>
        )}
      </List>
    </LuckBody>
  )
}
