/**
 * 充值
 */
import React, { useState } from 'react'
import {
  Modal,
  Text,
  Flex,
  Input,
  HelpIcon,
  BalanceInput,
  ModalContainer,
  ModalCloseButton,
  Heading,
  Button,
  ModalTitle,
  ModalHeader,
  ModalBody,
} from '@pancakeswap/uikit'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { useTranslation } from 'contexts/Localization'
import { useWeb3React } from '@web3-react/core'
import NumericalInput from './NumericalInput'

const StyledModalContainer = styled(ModalContainer)`
  max-width: 420px;
  min-width: 330px;
  padding-bottom: 20px;
`

const StyledModalBody = styled(ModalBody)`
  padding: 24px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Footer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  text-align: center;
`

interface ModalProps {
  tokenName: string
  amount: string | number
  onDismiss?: () => void
  onAmountChange: (_: any) => void
  handleConfirm: () => void
  isLoading: boolean
}

const GameRuleModal: React.FC<ModalProps> = ({
  tokenName,
  amount = '',
  isLoading,
  onDismiss,
  onAmountChange = (_: any) => undefined,
  handleConfirm = () => undefined,
}) => {
  const { account } = useWeb3React()
  const { t } = useTranslation()
  const { theme } = useTheme()

  return (
    <StyledModalContainer>
      <ModalHeader>
        <ModalTitle>
          <Heading>{t('Luck Recharge') + tokenName}</Heading>
        </ModalTitle>
        <ModalCloseButton onDismiss={onDismiss} />
      </ModalHeader>
      <StyledModalBody>
        <NumericalInput value={amount} onUserInput={onAmountChange} />
      </StyledModalBody>
      <Footer>
        <Button width="80%" className="list-token-manage-button" onClick={handleConfirm} isLoading={isLoading}>
          {t('Luck Recharge')}
        </Button>
      </Footer>
    </StyledModalContainer>
  )
}

export default GameRuleModal
