/**
 * DAO 玩法弹窗
 */
import React from 'react'
import {
  Modal,
  Text,
  Flex,
  HelpIcon,
  BalanceInput,
  Ticket,
  useTooltip,
  Skeleton,
  Button,
  ArrowForwardIcon,
  FlexProps,
  Box,
} from '@pancakeswap/uikit'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { useTranslation } from 'contexts/Localization'
import { useWeb3React } from '@web3-react/core'

const StyledModal = styled(Modal)`
  min-width: 320px;
  max-width: 370px;
  background: #fff7f7;
  & > div {
    border: 0;
  }
`
const BalancePanel = styled.div`
  width: 100%;
  height: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 10;
`
const BameBackground = styled(BalancePanel)`
  background: url('/images/luck/award_fail.png');
  background-repeat: no-repeat;
  background-size: cover;
`
const LuckGift = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color: #ff0003;
  font-size: 20px;
  font-weight: bold;
`

const DismissBtn = styled.button`
  font-size: 18px;
  border: none;
  color: #fff;
  background: url('/images/luck/luck_konw.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 174px;
  height: 51px;
  margin-left: 57px;
  margin-top: 20px;

  & > span {
    position: relative;
    top: -3px;
  }
`

interface ModalProps {
  onOpenRedPacket?: () => void
  onDismiss?: () => void
}

const GameRuleModal: React.FC<ModalProps> = ({ onOpenRedPacket, onDismiss = () => undefined }) => {
  const { t } = useTranslation()

  return (
    <StyledModal title="" onDismiss={onDismiss}>
      <BameBackground />
      {/* <LuckGift> {t('Unfortunately')}</LuckGift> */}
      <DismissBtn onClick={onOpenRedPacket}>{t('Open Red Envelope')}</DismissBtn>
    </StyledModal>
  )
}

export default GameRuleModal
