import React, { useEffect, useState } from 'react'
import { useTranslation } from 'contexts/Localization'
import { Card, useModal } from '@pancakeswap/uikit'
import useWeb3Provider from 'hooks/useActiveWeb3React'
import { useRedPacketContract, useERC20 } from 'hooks/useContract'
import { AppHeader, AppBody } from '../../components/App'
import { useMediaQuery, useScreen } from 'usehooks-ts'
import { useWeb3React } from '@web3-react/core'
import useToast from 'hooks/useToast'
import styled from 'styled-components'
import { parseEther } from 'ethers/lib/utils'
import { getRedPacketAddress } from 'utils/addressHelpers'
import Bignumber from 'bignumber.js'
import Loading from 'components/Loading'
import { useTransactionAdder } from '../../state/transactions/hooks'
import RechargeModal from './components/RechargeModal'
import LuckHistory from './LuckHistory'
import LuckMe from './LuckMe'
import LuckGrid from './components/LuckGrid'
import LuckRedPacketDetail from './LuckRedPacketDetail'

export const LuckMainBody = styled(Card)`
  border-radius: 24px;
  max-width: 750px;
  width: 100%;
  height: 1334px;
  z-index: 1;
`

export const LuckMain = styled.div`
  width: 100%;
  height: 775.5px;
  background: url('/images/luck/main_New.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`
export const LuckBackground = styled.div`
  // width: 100%;
  // height: 100px;
  // background: url('/images/luck/Lucky_Grand_Prize.png');
  // background-repeat: no-repeat;
  // background-size: cover;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  width: 100%;
  height: 100px;
  background: url('/images/luck/Lucky_Grand_Prize.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LuckMainBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('/images/luck/animated-gif.gif');
`

export const LuckTitle = styled.div`
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 22px;
  margin-top: 22px;
  font-weight: bold;
`

export const LuckSlogan = styled.div`
  width: 100%;
  height: 58px;
  text-align: center;
  font-size: 38px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0;
  margin-top: 14px;
  text-shadow: 4px 4px 1px rgba(248, 231, 28, 0.6);
`

const RechargeBtn = styled.button`
  font-size: 18px;
  border: none;
  color: #fff;
  background: url('/images/luck/recharge_btn.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 174px;
  height: 38px;

  & > span {
    position: relative;
    top: -3px;
  }
`

const LuckFooter = styled.div`
  width: 434px;
  height: 86px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
`

const FootBtn = styled.button`
  font-size: 18px;
  border: none;
  color: #fff;

  & > span {
    position: relative;
    top: -3px;
  }
`

const FootBigBtn = styled(FootBtn)`
  width: 171px;
  height: 68.6px;
`
const MyBtn = styled(FootBtn)`
  width: 77.9px;
  height: 68.6px;

  background: url('/images/luck/my_btn.png');
  background-repeat: no-repeat;
  background-size: cover;
`

const LuckGridPanel = styled.div`
  width: 434px;
  height: 422px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 66px;
`

export default function Luck({ tokens = [] }) {
  const { t } = useTranslation()
  const [showMe, setShowMe] = useState(false)
  const [showLuckHistory, setShowLuckHistory] = useState(false)
  const [showLuckRedPacketDetail, setShowLuckRedPacketDetail] = useState(false)
  const [currentDetailRedPacket, setCurrentDetailRedPacket] = useState()
  const [currentCoin, setCurrentCoin] = useState('')
  const [tokenAName, setTokenAName] = useState('')
  const [tokenBName, setTokenBName] = useState('')
  const [tokenIndex, setTokenIndex] = useState(0)
  //
  const { toastSuccess, toastError } = useToast()
  //
  const { account } = useWeb3React()
  const { library, connector } = useWeb3Provider()
  const addTransaction = useTransactionAdder()
  const redPacketContract = useRedPacketContract(true)
  console.log(tokens)
  const tokenAContract = useERC20(tokens[0])
  const tokenBContract = useERC20(tokens[0])
  //
  const redPacketContractAddress = getRedPacketAddress()
  //
  const [rechargeAmount, setRechargeAmount] = useState('')
  const [isInRecharge, setIsInRecharge] = useState(false)
  //
  const [tokenARedPackets, setTokenARedPackets] = useState([])
  const [tokenBRedPackets, setTokenBRedPackets] = useState([])
  //
  const [redPacketConfigCount, setRedPacketConfigCount] = useState(0)
  const [redPacketConfigRedPackets, setRedPacketConfigRedPackets] = useState([])

  const [openRecharge, onRechargeModalDismiss] = useModal(
    <RechargeModal
      tokenName={currentCoin}
      amount={rechargeAmount}
      onAmountChange={setRechargeAmount}
      handleConfirm={() => {
        if (parseInt(rechargeAmount) > 0) {
          setIsInRecharge(true)
          approveToken()
        }
      }}
      isLoading={isInRecharge}
    />,
    false,
    true,
    'luck_recharge_modal',
  )

  const approveToken = () => {
    const tokenContract = tokenIndex === 0 ? tokenAContract : tokenBContract
    tokenContract
      .approve(redPacketContractAddress, parseEther(rechargeAmount), {
        from: account,
      })
      .then((res) => {
        addTransaction(res, {
          summary: `Approve ${currentCoin} Token`,
          approval: { tokenAddress: tokens[tokenIndex], spender: redPacketContractAddress },
          type: 'approve',
        })
        toastSuccess(t('Approval Token Success'))
        setTimeout(depositToken, 5000)
      })
      .catch((error) => {
        toastError(t('Approval Token Failed'), error?.data?.message)
      })
  }

  const depositToken = () => {
    redPacketContract
      .deposit(tokens[tokenIndex], parseEther(rechargeAmount), {
        from: account,
      })
      .then((res) => {
        addTransaction(res, {
          summary: `Luck Recharge`,
          type: 'swap',
        })
        setIsInRecharge(false)
        onRechargeModalDismiss()
        toastSuccess(t('Luck Recharge') + t('Success'))
      })
      .catch((ex) => {
        toastError(t('Luck Recharge') + t('Lose'), ex?.data?.message)
      })
      .finally(() => {
        setIsInRecharge(false)
      })
  }

  const matches = useMediaQuery('(max-width:436px)')
  const screen = useScreen()
  const scaleStyle = {}
  const bodyHeight = {}
  const gridStyle = {}
  if (matches) {
    Object.assign(scaleStyle, {
      transform: 'scale(0.85)',
      overflowY: 'auto',
    })

    Object.assign(bodyHeight, {
      height: `${(screen.width / 436) * 775.5}px`,
    })

    Object.assign(gridStyle, {
      marginTop: '0px',
    })
  }

  //   function LoadErc20Name(address: string) {
  //     useTokenContract(address)
  //       .symbol()
  //       .then((res) => {
  //         console.info(res)
  //       })
  //   }
  useEffect(() => {
    tokenAContract.symbol().then((res) => {
      setTokenAName(res)
      setCurrentCoin(res)
    })

    tokenBContract.symbol().then((res) => {
      setTokenBName(res)
    })
  }, [tokenAContract, tokenBContract])

  // load reedpacket configs
  useEffect(() => {
    redPacketContract.totalConfigs().then((res) => {
      setRedPacketConfigCount(res)
    })
  }, [redPacketContract])

  // load reedpacket configs redpackets
  useEffect(() => {
    if (redPacketConfigCount === 0) return

    const reqs = []
    for (let i = 0; i < redPacketConfigCount; i++) {
      reqs.push(redPacketContract.getRedPacketHistoryWithConfigIdCount(i))
    }

    Promise.all(reqs).then((res) => {
      const packets = Array(reqs.length)
      res.forEach((i, index) => {
        packets[index] = i
      })

      setRedPacketConfigRedPackets(packets)
    })
  }, [redPacketConfigCount])

  // load red packets
  useEffect(() => {
    const reqs = []
    redPacketConfigRedPackets.forEach((count, configId) => {
      const startIndex = count < 10 ? 0 : count - 10
      reqs.push(redPacketContract.getRedPacketHistoryWithConfigIdBatch(configId, startIndex, count))
    })

    //
    Promise.all(reqs).then((res) => {
      const APackets = []
      const BPackets = []
      res.forEach((arr, configId) => {
        arr.forEach((packet, index) => {
          const startIndex = redPacketConfigRedPackets[configId] < 10 ? 0 : redPacketConfigRedPackets[configId] - 10
          if (packet.userAmounts.length > 0) {
            return
          }
          //
          if (
            packet.tokenContract === '0x94A839bEa7f22Ad09d28C36dbf515663E87BaA12' &&
            configId === 11 &&
            startIndex + index === 2
          ) {
            return
          }
          //
          if (packet.tokenContract === tokenAContract.address) {
            APackets.push({
              ...packet,
              configId,
              id: startIndex + index,
            })
          } else if (packet.tokenContract === tokenBContract.address) {
            BPackets.push({
              ...packet,
              configId,
              id: startIndex + index,
            })
          }
        })
      })
      //
      setTokenARedPackets(APackets)
      setTokenBRedPackets(BPackets)
      console.log(APackets)
      console.log(BPackets)
    })
  }, [redPacketConfigRedPackets])

  const btnMe = () => {
    setShowMe(true)
  }

  function RenderMain() {
    const selectedBtn = {
      backgroundColor: 'transparent',
      backgroundImage: "url('/images/luck/btn_selected.png')",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      color: '#d82c0b',
    }

    const normalBtn = {
      backgroundColor: 'transparent',
      backgroundImage: "url('/images/luck/btn.png')",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      color: '#fff',
    }
    const zindexStyle = {
      zIndex: '100000'
    }
    return (
      <LuckMain style={bodyHeight}>
        <LuckMainBackground style={scaleStyle} />
        <div style={zindexStyle}>
          <LuckTitle style={scaleStyle}>
            {currentCoin} &nbsp;
            {t('Red Envelope')}
          </LuckTitle>
          <LuckBackground style={scaleStyle} />
          <RechargeBtn onClick={openRecharge}>{t('Luck Recharge')}</RechargeBtn>
        </div>
        {/* <LuckSlogan style={scaleStyle}></LuckSlogan> */}
        <LuckGridPanel style={{ ...scaleStyle, ...gridStyle }}>
          <LuckGrid
            coinType={currentCoin}
            tokenIndex={tokenIndex}
            redPackets={tokenIndex === 0 ? tokenARedPackets : tokenBRedPackets}
            onShowDetail={(redPacket) => {
              setCurrentDetailRedPacket(redPacket)
              setShowLuckRedPacketDetail(true)
            }}
          />
        </LuckGridPanel>
        <LuckFooter style={scaleStyle}>
          <FootBigBtn
            style={currentCoin === tokenAName ? selectedBtn : normalBtn}
            onClick={() => {
              setCurrentCoin(tokenAName)
              setTokenIndex(0)
            }}
          >
            <span>
              {tokenAName}&nbsp;
              {t('Red Envelope')}
            </span>
          </FootBigBtn>
          {/* <FootBigBtn
            style={currentCoin === tokenBName ? selectedBtn : normalBtn}
            onClick={() => {
              setCurrentCoin(tokenBName)
              setTokenIndex(1)
            }}
          >
            <span>
              {tokenBName}&nbsp;
              {t('Red Envelope')}
            </span>
          </FootBigBtn> */}
          <MyBtn onClick={btnMe}>
            <span>{t('Luck My')}</span>
          </MyBtn>
        </LuckFooter>
      </LuckMain>
    )
  }

  function RenderMe() {
    return (
      <LuckMe
        tokenAAddress={tokens[0]}
        tokenAName={tokenAName}
        tokenBAddress={tokens[1]}
        tokenBName={tokenBName}
        onRecharge={(index) => {
          if (index === 0) {
            setCurrentCoin(tokenAName)
            setTokenIndex(0)
          } else {
            setCurrentCoin(tokenBName)
            setTokenIndex(1)
          }
          openRecharge()
        }}
        onBack={() => {
          setShowMe(false)
        }}
        onShowLuckHistory={() => {
          setShowLuckHistory(true)
        }}
      />
    )
  }

  const tokenMap = {}
  tokenMap[tokens[0]] = tokenAName
  tokenMap[tokens[1]] = tokenBName

  function RenderLuckHistory() {
    return (
      <LuckHistory
        tokenMap={tokenMap}
        onBack={() => {
          setShowLuckHistory(false)
        }}
        onShowDetail={(redPacket) => {
          setCurrentDetailRedPacket(redPacket)
          setShowLuckRedPacketDetail(true)
        }}
      />
    )
  }

  function RenderLuckRedPacketDetail() {
    return (
      <LuckRedPacketDetail
        tokenMap={tokenMap}
        redPacket={currentDetailRedPacket}
        onBack={() => {
          setShowLuckRedPacketDetail(false)
        }}
      />
    )
  }

  return (
    <>
      <AppBody>
        {showMe ? RenderMe() : RenderMain()}
        {showLuckHistory && RenderLuckHistory()}
        {showLuckRedPacketDetail && RenderLuckRedPacketDetail()}
      </AppBody>
    </>
  )
}
