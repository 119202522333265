// pages/stake.tsx
// 质押+多签页面
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useRedPacketContract } from 'hooks/useContract'
import Page from '../Page'
import Luck from './Luck'

const StakePage: React.FC = () => {
  const [tokens, setTokens] = useState([])
  const redPacketContract = useRedPacketContract(true)

  useEffect(() => {
    return redPacketContract.getAllSupportTokens().then((res) => {
      setTokens(res)
    })
  }, [])

  return <Page>{tokens.length > 0 && <Luck tokens={tokens} />}</Page>
}

export default StakePage
