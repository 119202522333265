import React, { useEffect, useState } from 'react'
import { useTranslation } from 'contexts/Localization'
import { BaseLayout, useModal } from '@pancakeswap/uikit'
import Bignumber, { BigNumber } from 'bignumber.js'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import useWeb3Provider from 'hooks/useActiveWeb3React'
import { useRedPacketContract } from 'hooks/useContract'
import useToast from 'hooks/useToast'
import { useTransactionAdder } from '../../../state/transactions/hooks'
import _, { set } from 'lodash'
import LuckOpenAwardSuccessModal from './LuckOpenAwardSuccessModal'
import LuckOpenAwardFailModal from './LuckOpenAwardFailModal'
import LuckOpenRedModal from './LuckOpenRedModal'

const Packet = styled.div`
  width: 142px;
  height: 215px;
`

const PacketContent = styled.div`
  width: 109px;
  height: 178px;
  position: relative;
  left: 16px;
  top: 14px;
  //border: 1px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PeopleTitle = styled.div`
  height: 23px;
  line-height: 23px;
  color: #fffcf8;
`

const CoinAmount = styled.div`
  height: 38px;
  line-height: 38px;
  color: #0A5637;
  font-size: 38px;
`

const CoinType = styled.div`
  height: 24px;
  line-height: 24px;
  color: #0A5637;
  font-size: 18px;
`

const PacketStatus = styled.div`
  height: 20px;
  line-height: 20px;
  padding: 0 7px;
  color: #f4826c;
  font-size: 12px;
  position: absolute;
  bottom: 12px;
  background:#267638;
  //background-image: linear-gradient(188deg,#D3EDBC 0%, #88C877 100%);
  box-shadow: 0 0 3px 2px rgba(140, 234, 44,  0.23), inset 0 0 3px 3px rgba(176, 218, 133,  0.3);
  border-radius: 27px;
  border-radius: 19.5px;
`

interface PacketProps {
  tokenIndex: number
  coinType: string
  redPacketProp: any
  onShowDetail: (redPacket: any) => void
}

export default function LuckPacket({
  coinType,
  tokenIndex,
  redPacketProp,
  onShowDetail = (redPacket: any) => undefined,
}: PacketProps) {
  const { t } = useTranslation()
  //
  const { toastSuccess, toastError } = useToast()
  const { library, connector } = useWeb3Provider()
  const { account } = useWeb3React()
  const addTransaction = useTransactionAdder()
  const redPacketContract = useRedPacketContract(true)
  const [redPacket, setRedPacket] = useState(undefined as any)
  const [joined, setJoined] = useState(false)
  const [fulled, setFulled] = useState(false)
  const [opened, setOpened] = useState(false)
  const [expired, setExpired] = useState(false)
  const [blockDiff, setBlockDiff] = useState(0)

  const [openAwardSuccess, openAwardSuccessDismiss] = useModal(<LuckOpenAwardSuccessModal />)
  const [openAwardFail, openAwardFailDismiss] = useModal(<LuckOpenAwardFailModal />)

  useEffect(() => {
    setRedPacket(redPacketProp)
  }, [redPacketProp])

  //
  useEffect(() => {
    if (redPacket) {
      const hasMy = _.indexOf(redPacket.joinedUsers, account) >= 0
      setJoined(hasMy)
      //
      setFulled(redPacket.joinedUsers.length >= redPacket.participantsNum.toNumber())
      //
      setOpened(redPacket.userAmounts.length > 0)
      //
      library.getBlockNumber().then((res) => {
        const diff = new BigNumber(res).minus(new BigNumber(redPacketProp.seedsBlockNumber.toBigInt())).toNumber()
        setExpired(diff > 255)
      })
    }
  }, [account, redPacket])

  const doOpenRedPacket = () => {
    redPacketContract
      .openRedPacket(redPacket.configId, redPacket.id, {
        from: account,
      })
      .then((res) => {
        addTransaction(res, {
          summary: `open RedPacket`,
          type: 'swap',
        })
        toastError(`${t('Open Red Envelope')}${t('Success')}`)
        openRedPacketModalDismiss()
      })
      .catch((error) => {
        toastError(`${t('Open Red Envelope')}${t('Lose')}`, error?.data?.message)
      })
  }

  const [openRedPacketModal, openRedPacketModalDismiss] = useModal(
    <LuckOpenRedModal onOpenRedPacket={doOpenRedPacket} />,
  )

  const openRedPacket = async () => {
    const newRepacket = await redPacketContract.getRedPacketDetail(redPacket.configId, redPacket.id)
    setRedPacket(newRepacket)
    //
    const currentBlockNumber = await library.getBlockNumber()
    const diff = new BigNumber(currentBlockNumber)
      .minus(new BigNumber(redPacketProp.seedsBlockNumber.toBigInt()))
      .toNumber()
    if (fulled && !opened && diff < 255) {
      openRedPacketModal()
    }
  }

  const joinRedPacket = async () => {
    if (fulled && !opened && !expired) {
      openRedPacket()
      return
    }

    if (opened) {
      onShowDetail(redPacket)
      return
    }

    if (joined) {
      return
    }

    if (!joined && fulled) return

    const balance = await redPacketContract.getUserAssets(account, redPacket.tokenContract)
    const lockedBalance = await redPacketContract.getUserLockedAssets(account, redPacket.tokenContract)

    const usefulBalance = new Bignumber(balance.toBigInt()).minus(new Bignumber(lockedBalance.toBigInt()))
    if (!usefulBalance.gte(new Bignumber(redPacket.totalAmount.toBigInt()))) {
      toastError(`${t('Insufficient balance, please recharge first')}`)
      return
    }

    redPacketContract
      .joinRedPacket(redPacket.configId, redPacket.id, {
        from: account,
      })
      .then((res) => {
        addTransaction(res, {
          summary: `join RedPacket`,
          type: 'swap',
        })
        openAwardSuccess()
        setJoined(true)
      })
      .catch((error) => {
        toastError(`${t('Open Red Envelope')}${t('Lose')}`, error?.data?.message)
        //openAwardFail()
      })
  }

  const bgPrefix = tokenIndex === 0 ? 'packet' : 'packet_b'

  const bgStyle = {
    backgroundColor: 'transparent',
    backgroundImage: `url('/images/luck/${bgPrefix}.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }

  const statusStyle = {}
  if (tokenIndex !== 0) {
    Object.assign(statusStyle, {
      background: '#88C877',
      border: '1px solid #88C877',
      color: '267638',
      fontWeight: 'bold',
    })
  } else {
    Object.assign(statusStyle, {
      background: '267638',
      border: '1px solid #88C877',
      color: '#fffcf8',
      fontWeight: 'bold',
    })
  }

  if ((joined && !fulled) || (fulled && !opened && expired)) {
    Object.assign(bgStyle, {
      filter: 'grayscale(100%)',
    })
  }

  let status = ''
  if (fulled && !opened) {
    status = t('Awaiting Draw')
  } else if (opened) {
    status = t('Open Luck Details')
  } else if (!fulled && joined) {
    status = t('Participated')
  } else if (!fulled && !joined) {
    status = t('Not Participated')
  }

  return (
    <Packet style={bgStyle}>
      {redPacket && (
        <PacketContent onClick={joinRedPacket}>
          <PeopleTitle style={{ color: tokenIndex === 0 ? '#fffcf8' : '#147C33 ' }}>
            {redPacket.participantsNum.toNumber()}
            {t('Luck Total Number')}
          </PeopleTitle>
          <CoinAmount style={{ color: tokenIndex === 0 ? '#0A5637' : '#022714' }}>
            {new Bignumber(redPacket.totalAmount.toBigInt()).div(1e18).toNumber()}
          </CoinAmount>
          <CoinType style={{ color: tokenIndex === 0 ? '#0A5637' : '#022714' }}>{coinType}</CoinType>
          <PacketStatus style={statusStyle}>{status}</PacketStatus>
        </PacketContent>
      )}
    </Packet>
  )
}
