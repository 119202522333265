import { useTranslation } from 'contexts/Localization'
import { useMediaQuery, useScreen } from 'usehooks-ts'
import styled from 'styled-components'
import Bignumber from 'bignumber.js'
import { ChevronLeftIcon } from '@pancakeswap/uikit'

const LuckBody = styled.div`
  width: 100%;
  height: 775.5px;
  background: linear-gradient(#88C877, #D3EDBC );
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 20;
`

const BackNav = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const List = styled.div`
  width: 93%;
  border-radius: 15px;
  height: calc(100% - 68px);
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
`

const ListContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  .list-item {
    width: 95%;
    border-bottom: 1px solid #e4e5e7;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .list-item-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
`

export default function LuckRedPacketDetail({ onBack = () => undefined, redPacket, tokenMap = {} }) {
  const { t } = useTranslation()
  const matches = useMediaQuery('(max-width:436px)')
  const screen = useScreen()
  const balanceStyle = {
    width: '388.89px',
  }
  const bodyHeight = {}
  if (matches) {
    Object.assign(balanceStyle, {
      transform: 'scale(0.85)',
    })

    Object.assign(bodyHeight, {
      height: `${(screen.width / 436) * 775.5}px`,
    })
  }

  const hideAddress = (address) => {
    return `${address.substring(0, 8)}****${address.substring(address.length - 6, address.length)}`
  }

  return (
    <LuckBody style={bodyHeight}>
      <BackNav>
        <ChevronLeftIcon width={32} height={32} style={{ position: 'absolute', left: '12px' }} onClick={onBack} />
        <span style={{ fontWeight: 'bold' }}>{t('Red Envelope Details')}</span>
      </BackNav>
      <List>
        <ListContent>
          <div key="list-head" className="list-item">
            <div className="list-item-row">
              <span style={{ minWidth: '120px', textAlign: 'center' }}>参与者</span>
              <span style={{ minWidth: '80px', textAlign: 'center' }}>奖励</span>
            </div>
          </div>
          {redPacket.joinedUsers.map((i, index) => (
            <div key={i} className="list-item">
              <div className="list-item-row">
                <span>{hideAddress(i)}</span>
                {redPacket.userAmounts.length > 0 ? (
                  <span style={{ color: 'green' }}>
                    + {new Bignumber(redPacket.userAmounts[index].toBigInt()).div(1e18).toNumber()}&nbsp;
                    {tokenMap[redPacket.tokenContract]}
                  </span>
                ) : (
                  <span style={{ color: 'gray' }}>{t('Awaiting Draw')}</span>
                )}
              </div>
            </div>
          ))}
        </ListContent>
      </List>
    </LuckBody>
  )
}
