/**
 * DAO 开红包成功
 */
import React from 'react'
import {
  Modal,
  Text,
  Flex,
  HelpIcon,
  BalanceInput,
  Ticket,
  useTooltip,
  Skeleton,
  Button,
  ArrowForwardIcon,
  FlexProps,
  Box,
} from '@pancakeswap/uikit'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { useTranslation } from 'contexts/Localization'
import { useWeb3React } from '@web3-react/core'

const StyledModal = styled(Modal)`
  min-width: 320px;
  max-width: 370px;
  text-align: center;
  background: #fff;
  border: 0;
  background: url('/images/luck/award_success.png');
  background-repeat: no-repeat;
  background-size: cover;

  & > div {
    border: 0;
  }
`
const CongratulationsDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-left: 20px;
  color: #ff0003;
`

const LuckGift = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-left: 20px;
  color: #ffe9bb;
  font-size: 40px;
  font-weight: bold;
`
// const LuckGift = styled(LuckGiftDiv)`

// `

const LuckGiftDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-left: 20px;
  color: #fff;
`
// const LuckGiftDetails = styled(LuckGiftDetailsDiv)`

// `

const DismissBtn = styled.button`
  font-size: 18px;
  border: none;
  color: #fff;
  background: url('/images/luck/luck_konw.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 174px;
  height: 51px;
  margin-left: 57px;
  margin-top: 20px;

  & > span {
    position: relative;
    top: -3px;
  }
`

interface ModalProps {
  onDismiss?: () => void
}

const GameRuleModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { account } = useWeb3React()
  const { t } = useTranslation()
  const { theme } = useTheme()
  console.info('account', account, theme)

  return (
    <StyledModal title="" onDismiss={onDismiss}>
      <CongratulationsDiv> {t('Congratulations')}</CongratulationsDiv>
      <LuckGift> {t('Luck Gift')}</LuckGift>
      <LuckGiftDetails> {t('Luck Gift Details')}</LuckGiftDetails>
      <DismissBtn onClick={onDismiss}>{t('Luck I understand')}</DismissBtn>
    </StyledModal>
  )
}

export default GameRuleModal
