import React, { useEffect, useState } from 'react'
import { useTranslation } from 'contexts/Localization'
import { AppHeader, AppBody } from '../../components/App'
import { useMediaQuery, useScreen } from 'usehooks-ts'
import styled from 'styled-components'
import { Tag, ChevronLeftIcon, useModal, Modal, Heading, Button, ModalProps } from '@pancakeswap/uikit'
import { useRedPacketContract } from 'hooks/useContract'
import { useWeb3React } from '@web3-react/core'
import Bignumber from 'bignumber.js'
import useToast from 'hooks/useToast'
import { useTransactionAdder } from '../../state/transactions/hooks'
import GameRuleModal from './components/LuckGameRuleModal'
import SendRedPacketModal from './components/SendRedPacketModal'

const LuckMeBody = styled.div`
  width: 100%;
  height: 775.5px;
  background: url('/images/luck/me_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`

const BalancePanel = styled.div`
  width: 100%;
  height: 173.78px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 10;
`

const FIFABalancePanel = styled(BalancePanel)`
  background: url('/images/luck/FIFAII_balance_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
`
const BTCDBalancePanel = styled(BalancePanel)`
  // background: url('/images/luck/BTHD_balance_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
`

const CoinTitle = styled.div`
  color: #fff;
  font-size: 22px;
  margin-top: 40px;
`

const CoinBalance = styled.div`
  color: #fff;
  font-size: 38px;
  margin-top: 10px;
`

const IncomePanel = styled.div`
  width: 92%;
  color: #fff;
  font-size: 20px;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const SendPacketButton = styled.button`
  width: 92%;
  height: 44px;
  color: #fff;
  font-size: 18px;
  background-image: linear-gradient(90deg, #d3edbc 0%, #88c877 100%);
  border-radius: 47px;
  border: none;
`
const CoinActions = styled.div`
  height: 110px;
  background: #fff;
  position: relative;
  z-index: 0;
  top: -20px;
  border-radius: 0 0 15px 15px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`
const CoinActionsOld = styled.div`
  height: 110px;
  // background: #fff;
  position: relative;
  z-index: 0;
  top: -20px;
  border-radius: 0 0 15px 15px;
  // box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`
const BackNav = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const FAQButton = styled.button`
  border: 1px solid #f9ac10;
  border-right: none;
  height: 28px;
  padding: 2px 12px;
  position: absolute;
  top: 90px;
  right: 0px;
  color: #0a5637;
  border-radius: 14px 0 0 14px;
  background: #fff4dc;
`

const ActionButton = styled.div`
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  top: 10px;

  > img {
    width: 33px;
    height: 33px;
  }
`

function toHex(currencyAmount): string {
  return `0x${currencyAmount.toString(16)}`
}

export default function LuckMe({
  tokenAAddress,
  tokenAName,
  tokenBAddress,
  tokenBName,
  onBack = () => undefined,
  onShowLuckHistory = () => undefined,
  onRecharge = (tokenIndex: number) => undefined,
}) {
  const { t } = useTranslation()
  const redPacketContract = useRedPacketContract(true)
  const { account } = useWeb3React()
  const addTransaction = useTransactionAdder()
  const { toastSuccess, toastError } = useToast()

  //
  const [tokenABalance, setTokenABalance] = useState(0)
  const [tokenBBalance, setTokenBBalance] = useState(0)
  //
  const [tokenAlockBalance, setTokenAlockBalance] = useState(0)
  const [tokenBlockBalance, setTokenBlockBalance] = useState(0)

  useEffect(() => {
    redPacketContract.getUserAssets(account, tokenAAddress).then((res) => {
      const val = new Bignumber(res.toBigInt()).div(1e18).toNumber()
      setTokenABalance(val)
    })

    // redPacketContract.getUserAssets(account, tokenBAddress).then((res) => {
    //   const val = new Bignumber(res.toBigInt()).div(1e18).toNumber()
    //   setTokenBBalance(val)
    // })
  }, [account, redPacketContract, tokenAAddress, tokenBAddress])

  // lock balance
  useEffect(() => {
    redPacketContract.getUserLockedAssets(account, tokenAAddress).then((res) => {
      const val = new Bignumber(res.toBigInt()).div(1e18).toNumber()
      setTokenAlockBalance(val)
    })

    // redPacketContract.getUserLockedAssets(account, tokenBAddress).then((res) => {
    //   const val = new Bignumber(res.toBigInt()).div(1e18).toNumber()
    //   setTokenBlockBalance(val)
    // })
  }, [account, redPacketContract, tokenAAddress, tokenBAddress])

  const matches = useMediaQuery('(max-width:436px)')
  const screen = useScreen()
  const balanceStyle = {
    width: '388.89px',
  }
  const bodyHeight = {}
  if (matches) {
    Object.assign(balanceStyle, {
      transform: 'scale(0.85)',
    })

    Object.assign(bodyHeight, {
      height: `${(screen.width / 436) * 775.5}px`,
    })
  }

  const [openFAQ] = useModal(<GameRuleModal />)
  const tokenMap = {}
  tokenMap[tokenAAddress] = tokenAName
  tokenMap[tokenBAddress] = tokenBName
  const [openSendRedPacketModal, sendRedPacketDismiss] = useModal(
    <SendRedPacketModal
      tokenMap={tokenMap}
      onDismiss={() => {
        sendRedPacketDismiss()
      }}
    />,
  )

  const withdraw = async (token) => {
    const lockedBalance = await redPacketContract.getUserLockedAssets(account, token)
    const balance = await redPacketContract.getUserAssets(account, token)

    const usefulBalance = new Bignumber(balance.toBigInt()).minus(new Bignumber(lockedBalance.toBigInt()))
    if (usefulBalance.lte(new Bignumber('0'))) {
      toastError(`${t('Current available withdrawal balance is 0, unable to withdraw')}`)
      return
    }

    redPacketContract
      .withdraw(token, toHex(usefulBalance), {
        from: account,
      })
      .then((res) => {
        addTransaction(res, {
          summary: `withdraw balance`,
          type: 'swap',
        })
        toastSuccess(`${t('Withdraw')}${t('Success')}`)
      })
      .catch((error) => {
        toastError(`${t('Withdraw')}${t('Lose')}`, error?.data?.message)
      })
  }

  return (
    <LuckMeBody style={bodyHeight}>
      <BackNav>
        <ChevronLeftIcon width={32} height={32} style={{ position: 'absolute', left: '12px' }} onClick={onBack} />
        <span style={{ fontWeight: 'bold' }}>{t('Luck My')}</span>
      </BackNav>
      <div>&nbsp;</div>
      <div style={balanceStyle}>
        <FIFABalancePanel>
          <CoinTitle>
            {tokenAName} {t('Luck Total')}
          </CoinTitle>
          <CoinBalance>{tokenABalance.toString()}</CoinBalance>
          <IncomePanel>
            <span>
              {tokenAName} {t('Luck Total locked')}
            </span>
            <span>{tokenAlockBalance}</span>
          </IncomePanel>
        </FIFABalancePanel>
        <CoinActions>
          <ActionButton
            onClick={() => {
              onRecharge(0)
            }}
          >
            <img src="/images/luck/recharge_icon.png" alt="" />
            <span>{t('Luck Recharge')}</span>
          </ActionButton>
          <ActionButton
            onClick={() => {
              withdraw(tokenAAddress)
            }}
          >
            <img src="/images/luck/withdrawal_icon.png" alt="" />
            <span>{t('Luck Withdrawal')}</span>
          </ActionButton>
          <ActionButton onClick={onShowLuckHistory}>
            <img src="/images/luck/detail_icon.png" alt="" />
            <span>{t('Luck Detail')}</span>
          </ActionButton>
        </CoinActions>
      </div>
      <div style={balanceStyle}>
        <BTCDBalancePanel>
          {/* <CoinTitle>
            {tokenBName} {t('Luck Total')}
          </CoinTitle>
          <CoinBalance>{tokenBBalance.toString()}</CoinBalance>
          <IncomePanel>
            <span>
              {tokenBName} {t('Luck Total locked')}
            </span>
            <span>{tokenBlockBalance}</span>
          </IncomePanel> */}
        </BTCDBalancePanel>
        <CoinActionsOld>
          {/* <ActionButton
            onClick={() => {
              onRecharge(1)
            }}
          >
            <img src="/images/luck/recharge_icon.png" alt="" />
            <span>{t('Luck Recharge')}</span>
          </ActionButton>
          <ActionButton
            onClick={() => {
              withdraw(tokenBAddress)
            }}
          >
            <img src="/images/luck/withdrawal_icon.png" alt="" />
            <span>{t('Luck Withdrawal')}</span>
          </ActionButton>
          <ActionButton onClick={onShowLuckHistory}>
            <img src="/images/luck/detail_icon.png" alt="" />
            <span>{t('Luck Detail')}</span>
          </ActionButton> */}
        </CoinActionsOld>
      </div>
      {/* <SendPacketButton onClick={openSendRedPacketModal}>{t('Luck Setting')}</SendPacketButton> */}
      <FAQButton onClick={openFAQ}>{t('Luck Playing Method')}</FAQButton>
    </LuckMeBody>
  )
}
