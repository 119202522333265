/**
 * 发红包
 */
import React, { useEffect, useState } from 'react'
import {
  Modal,
  Text,
  Flex,
  Input,
  HelpIcon,
  BalanceInput,
  ModalContainer,
  ModalCloseButton,
  Heading,
  Button,
  ModalTitle,
  ModalHeader,
  ModalBody,
} from '@pancakeswap/uikit'
import styled from 'styled-components'
import { useRedPacketContract } from 'hooks/useContract'
import { useTranslation } from 'contexts/Localization'
import { useWeb3React } from '@web3-react/core'
import Bignumber from 'bignumber.js'
import Select, { OptionProps } from 'components/Select/Select'
import useToast from 'hooks/useToast'
import _ from 'lodash'
import { useTransactionAdder } from '../../../state/transactions/hooks'

const StyledModalContainer = styled(ModalContainer)`
  max-width: 420px;
  min-width: 330px;
  padding-bottom: 20px;
`

const StyledModalBody = styled(ModalBody)`
  min-height: 400px;
  padding: 24px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Footer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  text-align: center;
`

interface ModalProps {
  tokenMap: Record<string, string>
  onDismiss?: () => void
}

const GameRuleModal: React.FC<ModalProps> = ({ tokenMap = {}, onDismiss }) => {
  const redPacketContract = useRedPacketContract(true)
  const { t } = useTranslation()
  const [options, updateOptions] = useState([])
  const [selectedConfig, updateSelectedConfig] = useState([])
  const { account } = useWeb3React()
  const addTransaction = useTransactionAdder()
  //
  const { toastSuccess, toastError } = useToast()
  const [isSendPacketing, setIsSendPacketing] = useState(false)

  useEffect(() => {
    //
    const fetchData = async () => {
      const count = await redPacketContract.totalConfigs()
      const cc = []
      await [...Array(count.toNumber())].forEach((__, id) => {
        redPacketContract.getRedPacketConfig(id).then((res) => {
          cc.push({ ...res, id })
          updateOpts(cc)
        })
      })
    }

    fetchData()
  }, [])

  const updateOpts = (configs) => {
    const opts = []
    configs.forEach((item, index) => {
      const amount = new Bignumber(item.totalAmount.toBigInt()).div(1e18).toNumber()

      opts.push({
        label: `配置${item.id + 1}: ${item.participantsNum}人， ${amount} ${tokenMap[item.tokenContract]}`,
        value: item.id,
      })
    })

    _.orderBy(opts, ['value'], ['asc'])
    updateOptions(opts)
  }

  const handleConfirm = () => {
    setIsSendPacketing(true)
    redPacketContract
      .sendRedPacket(selectedConfig, {
        from: account,
      })
      .then((res) => {
        addTransaction(res, {
          summary: `Send RedPacket`,
          type: 'swap',
        })
        toastSuccess(t('Luck Setting') + t('Success'))
        onDismiss()
      })
      .catch((error) => {
        toastError(t('Luck Setting') + t('Lose'), error?.data?.message)
      })
      .finally(() => {
        setIsSendPacketing(false)
      })
  }

  return (
    <StyledModalContainer>
      <ModalHeader>
        <ModalTitle>
          <Heading>{t('Luck Setting')}</Heading>
        </ModalTitle>
        <ModalCloseButton onDismiss={onDismiss} />
      </ModalHeader>
      <StyledModalBody>
        <Select
          options={options}
          placeHolderText={t('Select')}
          onOptionChange={(option: OptionProps) => {
            updateSelectedConfig(option.value)
          }}
        />
      </StyledModalBody>
      <Footer>
        <Button width="80%" className="list-token-manage-button" onClick={handleConfirm} isLoading={isSendPacketing}>
          {t('Luck Setting')}
        </Button>
      </Footer>
    </StyledModalContainer>
  )
}

export default GameRuleModal
